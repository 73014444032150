// Callouts
//
// Not quite alerts, but custom and helpful notes for folks reading the docs.
// Requires a base and modifier class.

.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;

  h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  code {
    border-radius: 3px;
  }

  + .bs-callout {
    margin-top: -5px;
  }
}

.bs-callout-danger {
  border-left-color: @alert-danger-border;

  h4 {
    color: @alert-danger-text;
  }
}

.bs-callout-warning {
  border-left-color: @alert-warning-border;

  h4 {
    color: @alert-warning-text;
  }
}

.bs-callout-info {
  border-left-color: @alert-info-border;

  h4 {
    color: @alert-info-text;
  }
}
