/*!
 * Bootstrap v3.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "variables.less";
@import "mixins.less";

// Reset and dependencies
// zaprefixovano uvnitr, aby nevznikaly paskvily ".b html" a ".b body"
@import "normalize.less";

.b { // zaprefixovano, kolize s nasimi styly...

  @import "print.less";
  @import "glyphicons.less";

  // Core CSS
  @import "scaffolding.less";
  @import "type.less";
  @import "code.less";
  @import "grid.less";
  @import "tables.less";
  @import "forms.less";
  @import "buttons.less";

  // Components
  @import "component-animations.less";
  @import "dropdowns.less";
  @import "button-groups.less";
  @import "input-groups.less";
  @import "navs.less";
  @import "navbar.less";
  @import "breadcrumbs.less";
  @import "pagination.less";
  @import "pager.less";
  @import "labels.less";
  @import "badges.less";
  @import "jumbotron.less";
  @import "thumbnails.less";
  @import "alerts.less";
  @import "callouts.less";
  @import "progress-bars.less";
  @import "media.less";
  @import "list-group.less";
  @import "panels.less";
  @import "responsive-embed.less";
  @import "wells.less";
  @import "close.less";

  // Components w/ JavaScript
  @import "modals.less";
  @import "tooltip.less";
  @import "popovers.less";
  @import "carousel.less";

  // Utility classes
  @import "utilities.less";
  @import "responsive-utilities.less";
}
